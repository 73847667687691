import React from 'react';
import { Box, Text } from '@chakra-ui/react';

import Layout from '../components/layout';

const NotFoundPage = () => (
  <Layout>
    <Box>
      <Text>404 Not Found</Text>
    </Box>
  </Layout>
);

export default NotFoundPage;
